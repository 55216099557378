'use client'

import {useLocale} from "next-intl";
import Link from "next/link";
import React from "react";
import {locale, localeConfigsMap} from "@/i18n.tsx";
import {LinkProps} from "next/dist/client/link";

type propType = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & LinkProps & {
  children?: React.ReactNode;
} & React.RefAttributes<HTMLAnchorElement>

export default function LocaleLink(props: propType) {
  const locale = useLocale() as locale
  return <Link {...props} href={localeConfigsMap[locale].prefix + props.href}>
    {props.children}
  </Link>
}
