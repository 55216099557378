export function classList(...classes: (string | false | null | undefined)[]) {
  return classes.filter(c => !!c).join(' ');
}

export async function sleep(ms: number) {
  return new Promise(r => setTimeout(r, ms))
}

export type falsy = false | 0 | "" | null | undefined

export function truthy<T>(any: T | falsy): any is T {
  return !!any
}

export type Dict<T> = { [key: string]: T }

export function mapBy<K extends Dict<any>>(list: K[], by: keyof K): Dict<K> {
  return list.reduce((items, item) => ({
    ...items,
    [item[by]]: item
  }), {})
}

export function sum<T>(...args: [number[]] | [T[], (item: T) => number]): number {
  if (args.length === 2) {
    return args[0].reduce((sum: number, element) => sum + args[1](element), 0)
  }
  return args[0].reduce((sum: number, element: number) => sum + element, 0)
}
