import {getLocale as nextIntlGetLocale, getRequestConfig} from 'next-intl/server';
import {Formats} from "intl-messageformat";
import {notFound} from "next/navigation";

import en from '../translations/en.json';
import es from '../translations/es.json';
import fr from '../translations/fr.json';

export const messagesMap = {
  'en': en,
  'es': es,
  'fr': fr,
} as const;

export const defaultLocale = 'en';
export type locale = 'en' | 'es' | 'fr';
export const locales = [defaultLocale, 'es', 'fr'] as const;

export const opengraphLocaleMap = {
  'en': 'en_US',
  'es': 'es_ES',
  'fr': 'fr_FR',
} as const;

export const englishConfig = {
  code: 'en',
  iso: 'en-US',
  shortName: 'Eng',
  name: 'English',
  prefix: '',
} as const;

export const spanishConfig = {
  code: 'es',
  iso: 'es-ES',
  shortName: 'Spa',
  name: 'Español',
  prefix: '/es',
} as const;

export const frenchConfig = {
  code: 'fr',
  iso: 'fr-FR',
  shortName: 'Fra',
  name: 'Français',
  prefix: '/fr',
} as const;

export type localeConfigTypes = typeof englishConfig
  | typeof spanishConfig
  | typeof frenchConfig

export const localeConfigs = [
  englishConfig,
  spanishConfig,
  frenchConfig
] as const;

export const localeConfigsMap = {
  en: englishConfig,
  es: spanishConfig,
  fr: frenchConfig
} as const;

export function formatCurrency(currency: string): Partial<Formats> {
  return {
    number: {currency: {style: "currency", currency: currency}}
  }
}

export default getRequestConfig(async (config) => {
  const _locale = config.locale || 'en';
  if (!locales.includes(_locale as any)) {
    notFound();
  }
  return {
    messages: messagesMap[_locale as locale],
    defaultTranslationValues: {
      "br": () => <br/>,
      "h1": (chunks) => <h1>{chunks}</h1>,
      "h2": (chunks) => <h2>{chunks}</h2>,
      "h3": (chunks) => <h3>{chunks}</h3>,
      "h4": (chunks) => <h4>{chunks}</h4>,
      "h5": (chunks) => <h5>{chunks}</h5>,
      "h6": (chunks) => <h6>{chunks}</h6>,
      "p": (chunks) => <p>{chunks}</p>,
      "b": (chunks) => <b>{chunks}</b>,
      "i": (chunks) => <i>{chunks}</i>,
      "ul": (chunks) => <ul>{chunks}</ul>,
      "li": (chunks) => <li>{chunks}</li>,
    },
  }
})

export async function getLocale(): Promise<localeConfigTypes> {
  const locale = await nextIntlGetLocale() as locale;
  return localeConfigsMap[locale]
}
